<script setup>
import router from "@/router";
import { onUnmounted, computed, ref, watch } from "vue";
import Navigation from "@/components/Home/Navigation.vue";
import Roadmap from "@/components/Home/Roadmap.vue";
import Footer from "../components/Home/Footer.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import store from "@/store";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Fixed from "@/components/Fixed.vue";
import Overlay from "@/components/Overlay.vue";
import Modal from "@/components/Modal.vue";

const loading = ref(false);

const connect = async () => {
  await store.dispatch("mint/connect", true);
};

const disconnect = async () => {
  await store.dispatch("mint/disconnect", true);
};

const connection = computed(() => {
  return store.state.mint.connection;
});

const mintQuantity = ref(1);

const mint = async () => {
  loading.value = true;
  await store.dispatch("mint/mint", mintQuantity.value);
  loading.value = false;
};

const details = ref(null);
const getDetails = async () => {
  details.value = null;
  let detailFetch = await store.dispatch("mint/details");

  if (detailFetch) {
    details.value = detailFetch;
  }
};

watch(connection, (newVal, oldVal) => {
  if (newVal?.address) {
    getDetails();
  }
});

onUnmounted(() => {
  disconnect();
});
</script>

<template>
  <div class="home relative">
    <div
      class="h-screen w-full bg-blue flex flex-col bg-cover bg-center"
      :style="{
        'background-image': `url('${require('@/assets/backgrounds/cave-v1-blurred.jpg')}')`,
      }"
    >
      <NavigationNew mode="light" />

      <Breadcrumbs
        :links="[
          { routeName: 'Home', label: 'Home' },
          { routeName: 'Buy', label: 'Buy' },
          { label: 'Mint Now' },
        ]"
      />

      <div class="flex-grow w-full flex items-center justify-center">
        <!-- <router-link class="container" :to="{ name: 'About' }">
          <img :src="require('@/assets/v2/home/characters-min.png')" class="pb-12" />
        </router-link> -->
        <div class="bg-white p-6 md:p-8 rounded-xl box-shadow-std">
          <div class="font-brother font-black mb-6 text-center text-2xl">Mint Now</div>

          <div v-if="connection?.address">
            <div class="text-center text-gray-400 mb-4">
              <div>Connected with: {{ connection.address }}</div>
              <div class="underline text-ch1blue cursor-pointer" @click="disconnect">
                disconnect
              </div>
            </div>
            <div v-if="details">
              <div class="mb-4 text-lg"><strong>Price:</strong> {{ details.price }} ETH each</div>
              <div v-if="details?.maxMintable" class="form-group">
                <label>Quantity to mint</label>
                <div class="input-group">
                  <select v-model="mintQuantity" class="input">
                    <option :value="null">Select quantity</option>
                    <option v-for="i in details?.maxMintable" :key="i" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
              <div class="flex justify-center">
                <button class="btn btn-green" @click="mint" :disabled="!mintQuantity">Mint</button>
              </div>
            </div>
            <div
              v-if="connection?.network?.chainId !== 1"
              class="font-bold text-2xl text-red-400 text-center italic"
            >
              Must be on the Ethereum mainnet
            </div>
          </div>
          <div v-else>
            <button @click="connect" class="btn btn-blue btn-red font-brother font-bold text-lg">
              Connect wallet
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Fixed v-if="loading">
    <Overlay>
      <Modal>Mint in progress, check your wallet provider</Modal>
    </Overlay>
  </Fixed>
</template>

<style lang="pcss" scoped></style>
