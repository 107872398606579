<script setup>
import router from "@/router";
import { onMounted, defineProps, ref, reactive, watch, computed } from "vue";
import Navigation from "@/components/Home/Navigation.vue";
import Roadmap from "@/components/Home/Roadmap.vue";
import Footer from "../components/Home/Footer.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import store from "@/store";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckoutForm from "@/components/Payment/StripeCheckoutForm.vue";
import OrderHistory from "@/components/Payment/OrderHistory.vue";

const loading = ref(false);
const checkoutForm = reactive({
  recipient: null,
  quantity: 1,
  discount_code: null,
});
const estimate = ref(null);
const order = ref(null);

const getEstimate = async () => {
  loading.value = true;
  estimate.value = null;
  order.value = null;
  let req = await store.dispatch("buy/estimate", checkoutForm);

  if (req) {
    estimate.value = req;
  }
  loading.value = true;
};

const goToCheckout = async () => {
  loading.value = true;

  let req = await store.dispatch("buy/checkout", checkoutForm);

  if (req) {
    order.value = req;
  }

  loading.value = false;
};

watch(checkoutForm, (newVal, oldVal) => {
  if (newVal.quantity !== oldVal.quantity || newVal.discount_code !== newVal.discount_code) {
    estimate.value = null;
    order.value = null;
  } else {
    getEstimate();
  }
});

onMounted(async () => {
  await getEstimate();
});

const clearOrder = () => {
  order.value = null;
};

const formatDollar = (num) => {
  return `$${(num / 100).toFixed(2)}`;
};

const isLoggedIn = computed(() => {
  return store.getters["auth/isLoggedIn"];
});

const login = async () => {
  router.push({ name: "Login", query: { from: encodeURI("/buy/credit") } });
};

const user = computed(() => {
  return store.state.auth.user;
});

const allWallets = computed(() => {
  if (!user.value) {
    return [];
  }
  // if (user.value?.user_verified_at) {
  return [...user.value?.custodial_wallets, ...user.value?.wallets];
  // }

  // return [...user.value?.custodial_wallets];
});

const showOrderHistory = ref(false);
</script>

<template>
  <div>Unavailable</div>
  <!-- <div class="home relative">
    <div
      class="h-screen w-full bg-blue flex-col bg-cover bg-center overflow-auto"
      :style="{
        'background-image': `url('${require('@/assets/backgrounds/inside-shop-v1-blurred.jpg')}')`,
      }"
    >
      <NavigationNew mode="light" />

      <Breadcrumbs
        :links="[
          { routeName: 'Home', label: 'Home' },
          { routeName: 'Buy', label: 'Buy' },
          { label: 'Buy Portal Pass' },
        ]"
      />

      <template v-if="!isLoggedIn" class="mt-8">
        <div
          class="p-8 m-4 w-full max-w-md bg-white rounded-xl box-shadow-std text-center flex items-center justify-center flex-col mx-auto"
        >
          <div class="mb-6 font-bold">
            You must be logged in to<br />
            buy a portal expedition pass
          </div>
          <div>
            <button @click="login" class="btn bg-ch1blue text-white">Login</button>
          </div>
        </div>
      </template>
      <div class="flex items-center justify-center p-4" v-else>
        <div class="max-w-lg">
          <div class="p-6 md:p-8 bg-white rounded-xl box-shadow-std">
            <div class="font-brother font-black mb-4 text-center text-2xl">Buy a Portal Pass</div>
            <div class="text-gray-800 mb-6 text-center" v-if="!order">
              Buy a portal expedition pass and receive your <br class="hidden md:inline" />
              character when the next portal expedition group embarks
              <br class="hidden md:inline" />(see details
              <router-link
                :to="{ name: 'PortalRedemption' }"
                class="underline text-ch1blue"
                target="_blank"
                >here</router-link
              >).
            </div>

            <div class="italic text-gray-400 mb-6 text-center" v-if="!order">
              Select the recipient wallet and quantity you wish to purchase, enter a discount code
              if you have it, then click "Checkout"
            </div>

            <div class="" v-if="!order">
              <div class="form-group">
                <label>Recipient wallet</label>
                <div class="input-group">
                  <select v-model="checkoutForm.recipient" class="input">
                    <option :value="null">Select recipient wallet</option>
                    <option
                      v-for="(wallet, w) in allWallets"
                      :key="w"
                      :value="wallet.wallet_address"
                    >
                      {{ wallet.username }}
                      <span v-if="wallet.is_custodial">(Society Custodial)</span>
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label>Quantity to buy</label>
                <div class="input-group">
                  <select v-model="checkoutForm.quantity" class="input">
                    <option :value="null">Select quantity</option>
                    <option v-for="i in 20" :key="i" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label>Discount Code</label>
                <div
                  class="input-group"
                  :class="{
                    'input-green': estimate?.discount_code && estimate?.discount_code_valid,
                    'input-red': estimate?.discount_code && !estimate?.discount_code_valid,
                  }"
                >
                  <input v-model="checkoutForm.discount_code" class="input" />
                </div>
              </div>
            </div>
            <div class="flex justify-center" v-if="!estimate">
              <button class="btn btn-green" @click="getEstimate">Get Pricing Info</button>
            </div>
            <div v-else-if="!order" class="mt-8">
              <div class="mb-4">
                <div class="grid grid-cols-2 gap-4 w-full">
                  <div class="text-right font-bold">Subtotal:</div>
                  <div>{{ formatDollar(estimate.subtotal) }}</div>
                </div>
                <div class="grid grid-cols-2 gap-4 w-full mt-2">
                  <div class="text-right font-bold">Discounts:</div>
                  <div>{{ formatDollar(estimate.discounts) }}</div>
                </div>
                <div class="text-center text-green-400 text-xs" v-if="estimate.discount_reason">
                  {{ estimate.discount_reason }}
                </div>
                <div class="grid grid-cols-2 gap-4 w-full mt-2">
                  <div class="text-right font-bold">Total:</div>
                  <div>{{ formatDollar(estimate.total) }}</div>
                </div>
              </div>
              <div class="flex justify-center">
                <button @click="goToCheckout" class="btn btn-green">Checkout</button>
              </div>
            </div>
            <div v-else>
              <div class="mb-4">
                <div class="grid grid-cols-2 gap-4 w-full">
                  <div class="text-right font-bold">Subtotal:</div>
                  <div>{{ formatDollar(estimate.subtotal) }}</div>
                </div>
                <div class="grid grid-cols-2 gap-4 w-full">
                  <div class="text-right font-bold">Discounts:</div>
                  <div>{{ formatDollar(estimate.discounts) }}</div>
                </div>
                <div class="grid grid-cols-2 gap-4 w-full">
                  <div class="text-right font-bold">Total:</div>
                  <div>{{ formatDollar(estimate.total) }}</div>
                </div>
              </div>
              <StripeCheckoutForm
                :secret="order.stripe_payment_intent_secret"
                :checkout="order.checkout_id"
              />
              <div class="mt-4">
                <div class="flex justify-center">
                  <div
                    class="text-center underline text-ch1blue cursor-pointer"
                    @click="clearOrder"
                  >
                    Go back
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-4">
            <div
              class="underline text-white font-bold cursor-pointer"
              @click="() => (showOrderHistory = true)"
            >
              See previous orders
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <OrderHistory v-if="showOrderHistory" @overlay="() => (showOrderHistory = false)" /> -->
</template>

<style lang="pcss" scoped></style>
