<script setup>
import router from "@/router";
import { onMounted, defineProps, ref } from "vue";
import Navigation from "@/components/Home/Navigation.vue";
import Roadmap from "@/components/Home/Roadmap.vue";
import Footer from "../components/Home/Footer.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

const props = defineProps({
  preview: String,
});

const willredirect = ref(false);

// onMounted(() => {
//   if (props.preview !== "yes") {
//     router.push({ name: "Poker" });
//   } else {
//     willredirect.value = false;
//   }
// });
</script>

<template>
  <div class="home relative">
    <div
      class="h-screen w-full bg-blue flex flex-col bg-cover bg-bottom"
      :style="{
        'background-image': `url('${require('@/assets/backgrounds/shop-v1-blurred.jpg')}')`,
      }"
    >
      <NavigationNew mode="light" />

      <Breadcrumbs :links="[{ routeName: 'Home', label: 'Home' }, { label: 'Buy A Character' }]" />

      <div class="flex-grow flex items-center justify-center flex-col p-4">
        <div class="p-6 md:p-8 rounded-xl bg-white text-center max-w-lg box-shadow-std">
          <div class="mb-4 font-brother text-2xl font-black">Join the Society of the Hourglass</div>
          <div class="mb-6">
            <div class="mb-2">
              Mint on the Ethereum mainnet with your self-custody wallet using ETH
            </div>
            <div class="flex justify-center">
              <router-link :to="{ name: 'Mint' }" class="btn">Mint Now</router-link>
            </div>
          </div>
          <!-- <div class="flex items-center justify-center w-3/4 mx-auto mb-6">
            <div class="flex-grow border-t border-gray-200"></div>
            <div class="mx-4">or</div>
            <div class="flex-grow border-t border-gray-200"></div>
          </div>
          <div>
            <div class="mb-2">2. Buy a portal expedition pass with a credit card</div>
            <div class="flex justify-center">
              <router-link :to="{ name: 'BuyCredit' }" class="btn">Buy Now</router-link>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.top-banner {
  @apply bg-green-500 w-full mb-6 py-2 text-center px-4;
}
.hero {
  @apply flex flex-col bg-cover bg-center items-center w-full;
}
.hero-inner {
  @apply h-screen flex flex-col bg-cover bg-center items-center w-full;
}
.hero-content {
  @apply flex-grow flex items-center justify-center flex-col;
}
.hero-text {
  @apply font-maindisplay font-black text-white text-xl md:text-3xl text-center mx-4;
  text-shadow: -4px 4px 0px rgba(0, 0, 0, 0.15);
}
.hero-bottom-cta {
  @apply flex justify-center items-center;
}
.hero-bottom-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  z-index: 200;
  @apply top-6 text-white px-6 py-4 rounded-lg bg-ch1blue-dark font-maindisplay text-xl relative;
}
.story {
  z-index: 100;
  @apply relative pb-6 md:pb-12 lg:pb-0 bg-repeat;
}
.story-nav {
  @apply pt-12 pb-4 lg:pb-12;
}
.story-bg {
  @apply w-full relative opacity-0 lg:opacity-100 mx-auto bg-contain md:bg-fixed hidden lg:block;
  max-width: 1400px;
}
.story-inner {
  @apply lg:absolute top-0 left-0 right-0 bottom-0 mx-auto;
  max-width: 1400px;
}
.story-text {
  @apply w-full md:w-2/3 lg:w-1/2 pt-0 p-8;
}
.story-title {
  @apply font-maindisplay text-2xl lg:text-6xl text-blue font-black mt-0 mb-6 lg:mt-4 lg:mb-12;
}
.story-body {
  @apply text-base md:text-lg lg:text-xl xl:text-2xl mb-8;
}
.story-body p {
  @apply mb-4 md:mb-10;
}
.story-bottom-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  @apply text-white px-6 py-4 rounded-lg bg-ch1red font-maindisplay text-xl relative inline-block;
}

.signup-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  @apply text-white px-12 py-4 rounded-lg bg-ch1blue font-maindisplay text-xl relative;
}

.cta-title {
  text-shadow: -4px 4px 0px rgba(0, 0, 0, 0.15);
  @apply font-maindisplay text-white text-2xl mb-6;
}
.cta-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  @apply text-white px-12 py-4 rounded-lg bg-ch1blue font-maindisplay text-xl relative;
}
</style>
