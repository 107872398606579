<script setup>
import router from "@/router";
import { onMounted, defineProps, ref } from "vue";
import Navigation from "@/components/Home/Navigation.vue";
import Roadmap from "@/components/Home/Roadmap.vue";
import Footer from "../components/Home/Footer.vue";
import NavigationNew from "@/components/NavigationNew.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import store from "@/store";

const loading = ref(true);
const props = defineProps({
  checkout_id: String,
  from_checkout: [Boolean, String],
});

const details = ref(null);
const fetchCheckout = async () => {
  loading.value = true;
  let req = await store.dispatch("buy/details", props.checkout_id);
  details.value = req;
  loading.value = false;
};

onMounted(() => {
  fetchCheckout();
});

const formatDollar = (num) => {
  return `$${(num / 100).toFixed(2)}`;
};
</script>

<template>
  <div class="home relative">
    <div
      class="h-screen w-full bg-blue flex flex-col bg-cover bg-center"
      :style="{
        'background-image': `url('${require('@/assets/backgrounds/shop-v1-blurred.jpg')}')`,
      }"
    >
      <NavigationNew mode="light" />

      <Breadcrumbs
        :links="[
          { routeName: 'Home', label: 'Home' },
          { routeName: 'Buy', label: 'Buy' },
          { label: props.from_checkout ? 'Successful Purchase!' : 'Order Details' },
        ]"
      />

      <div class="flex-grow flex items-center justify-center flex-col p-4">
        <div class="p-6 md:p-8 rounded-xl bg-white text-center max-w-lg box-shadow-std">
          <div v-if="props.from_checkout">
            <div class="mb-4 font-brother text-2xl font-black">
              Welcome the Society of the Hourglass!
            </div>
            <div class="text-gray-800 mb-6 text-center">
              You have successfully purchased a portal expedition pass (expedition details
              <router-link
                :to="{ name: 'PortalRedemption' }"
                class="underline text-ch1blue"
                target="_blank"
                >here</router-link
              >), you will receive your character in your wallet once the expedition embarks!
            </div>
            <div class="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              <div class="rounded-lg overflow-hidden relative">
                <img :src="require('@/assets/backgrounds/arcade-v3.jpg')" />
                <router-link
                  class="absolute top-0 left-0 w-full h-full flex items-center justify-center text-center font-brother font-black"
                  :to="{ name: 'Arcade' }"
                >
                  <div class="bg-black bg-opacity-50 rounded-lg p-4 text-white">
                    Visit the arcade
                  </div>
                </router-link>
              </div>
              <div class="rounded-lg overflow-hidden relative">
                <img :src="require('@/assets/backgrounds/gallery-v4.jpg')" />
                <router-link
                  class="absolute top-0 left-0 w-full h-full flex items-center justify-center text-center font-brother font-black"
                  :to="{ name: 'Gallery' }"
                >
                  <div class="bg-black bg-opacity-50 rounded-lg p-4 text-white">
                    Visit the gallery
                  </div>
                </router-link>
              </div>
            </div>
            <div class="italic text-gray-400 mb-2 text-center">Order details below:</div>
          </div>

          <div v-else>
            <div class="mb-4 font-brother text-2xl font-black">Order details</div>
          </div>

          <div v-if="details">
            <div class="w-full text-center mb-4">
              <div class="font-bold">Checkout ID:</div>
              <div class="">{{ details.checkout_id }}</div>
            </div>

            <div class="grid grid-cols-2 gap-4 w-full">
              <div class="text-right font-bold">Quantity:</div>
              <div class="text-left">{{ details.quantity }}</div>
            </div>

            <div class="grid grid-cols-2 gap-4 w-full">
              <div class="text-right font-bold">Total:</div>
              <div class="text-left">{{ formatDollar(details.total) }}</div>
            </div>

            <div class="grid grid-cols-2 gap-4 w-full" v-if="details.discounts">
              <div class="text-right font-bold">Discounts:</div>
              <div class="text-left">{{ formatDollar(details.discounts) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="pcss" scoped>
.top-banner {
  @apply bg-green-500 w-full mb-6 py-2 text-center px-4;
}
.hero {
  @apply flex flex-col bg-cover bg-center items-center w-full;
}
.hero-inner {
  @apply h-screen flex flex-col bg-cover bg-center items-center w-full;
}
.hero-content {
  @apply flex-grow flex items-center justify-center flex-col;
}
.hero-text {
  @apply font-maindisplay font-black text-white text-xl md:text-3xl text-center mx-4;
  text-shadow: -4px 4px 0px rgba(0, 0, 0, 0.15);
}
.hero-bottom-cta {
  @apply flex justify-center items-center;
}
.hero-bottom-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  z-index: 200;
  @apply top-6 text-white px-6 py-4 rounded-lg bg-ch1blue-dark font-maindisplay text-xl relative;
}
.story {
  z-index: 100;
  @apply relative pb-6 md:pb-12 lg:pb-0 bg-repeat;
}
.story-nav {
  @apply pt-12 pb-4 lg:pb-12;
}
.story-bg {
  @apply w-full relative opacity-0 lg:opacity-100 mx-auto bg-contain md:bg-fixed hidden lg:block;
  max-width: 1400px;
}
.story-inner {
  @apply lg:absolute top-0 left-0 right-0 bottom-0 mx-auto;
  max-width: 1400px;
}
.story-text {
  @apply w-full md:w-2/3 lg:w-1/2 pt-0 p-8;
}
.story-title {
  @apply font-maindisplay text-2xl lg:text-6xl text-blue font-black mt-0 mb-6 lg:mt-4 lg:mb-12;
}
.story-body {
  @apply text-base md:text-lg lg:text-xl xl:text-2xl mb-8;
}
.story-body p {
  @apply mb-4 md:mb-10;
}
.story-bottom-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  @apply text-white px-6 py-4 rounded-lg bg-ch1red font-maindisplay text-xl relative inline-block;
}

.signup-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  @apply text-white px-12 py-4 rounded-lg bg-ch1blue font-maindisplay text-xl relative;
}

.cta-title {
  text-shadow: -4px 4px 0px rgba(0, 0, 0, 0.15);
  @apply font-maindisplay text-white text-2xl mb-6;
}
.cta-btn {
  box-shadow: -8px 8px 0px rgba(0, 0, 0, 0.15);
  @apply text-white px-12 py-4 rounded-lg bg-ch1blue font-maindisplay text-xl relative;
}
</style>
